import highlightedCategories, { HighlightedCategory } from "@/app/ec/dental-supplies/highlightedCategories";
import topBrands, { TopBrands } from "@/app/ec/dental-supplies/topBrands";
import fixURI from "@/utils/fixURI";
import logger from "@/utils/logger";

/**
 * Gets the top categories from the server and caches them for 24 hours.
 * @returns
 */
export default async function getTopCategories(): Promise<GetTopCategoriesReturnType> {
  const returnObj: GetTopCategoriesReturnType = {
    topCategories: [] as WindfallRestfulResponse.ResponsePieces.TopCategory[],
    topBrands: topBrands,
    highlightedCategories: highlightedCategories,
  };
  try {
    const res = await fetch(
      `http://${process.env.WINDFALL_HOST}:${process.env.WINDFALL_PORT}/windfall-web/rest/category/top-categories?verId=${process.env.NEXT_PUBLIC_VERTICAL_ID}&culture=en_US`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        next: { revalidate: 3600 }, // 1 hour
      },
    );
    const response = (await res.json()) as WindfallRestfulResponse.ResponsePacket<
      WindfallRestfulResponse.ResponsePieces.TopCategory[]
    >;
    if (response) {
      returnObj.topCategories = response.Payload ? response.Payload : [];
      returnObj.topCategories = returnObj.topCategories.map((tc) => {
        return {
          ...tc,
          url: fixURI(tc.url),
        };
      });
    }
    return returnObj;
  } catch (error) {
    logger.error(error);
    return returnObj;
  }
}

type GetTopCategoriesReturnType = {
  topCategories: WindfallRestfulResponse.ResponsePieces.TopCategory[];
  topBrands: TopBrands[];
  highlightedCategories: HighlightedCategory[];
};
