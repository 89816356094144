import StandardPageFooter from "@/app/layouts/standard/footer/standardPageFooter";
import StandardPageHeader from "@/app/layouts/standard/header/standardPageHeader";
import getTopCategories from "@/fetch/getTopCategories";
import { Suspense } from "react";

export default async function StandardPage({
  children,
  headerSearchEnabled = true,
}: {
  children: React.ReactNode;
  headerSearchEnabled?: boolean;
}) {
  const { topCategories } = await getTopCategories();
  return (
    <div id="full-page">
      <Suspense>
        <StandardPageHeader topCategoriesFromServer={topCategories} headerSearchEnabled={headerSearchEnabled} />
      </Suspense>
      {children}
      <StandardPageFooter />
    </div>
  );
}
