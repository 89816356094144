const topBrands: TopBrands[] = [
  {
    name: "Mydent International",
    id: "mydent",
    searchParameter: "/search?filter.manufacturer=Mydent",
  },
  {
    name: "Metrex",
    id: "metrex",
    searchParameter: "/search?filter.manufacturer=Metrex",
  },
  {
    name: "Premier Dental",
    id: "premier-dental",
    searchParameter: "https://www.net32.com/distributors/premier-dental",
  },
  {
    name: "Kerr",
    id: "kerr",
    searchParameter: "/search?filter.manufacturer=Kerr&filter.manufacturer=Kerr%20TotalCare",
  },
  {
    name: "DMG America (Zenith)",
    id: "dmg-america-zenith",
    searchParameter: "/search?filter.manufacturer=DMG+America+%28Zenith%29",
  },
  {
    name: "3M",
    id: "3m",
    searchParameter: "https://www.net32.com/3m-oral-care",
  },
  {
    name: "Sultan Healthcare",
    id: "sultan-healthcare",
    searchParameter: "/search?filter.manufacturer=Sultan",
  },
  {
    name: "Septodont",
    id: "septodont",
    searchParameter: "/search?filter.manufacturer=Septodont",
  },
];

export default topBrands;

export type TopBrands = {
  name: string;
  id: string;
  searchParameter: string;
};
