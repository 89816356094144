const highlightedCategories: HighlightedCategory[] = [
  {
    name: "Gloves",
    id: "gloves",
    url: "/ec/gloves-infection-control-personal-l-509-569",
    image: "/media/shared/common/mp/house-brand/house-brand-premium/small/nitrile-exam-gloves-blue.png",
  },
  {
    name: "Wipes & towelettes, disinfecting",
    id: "wipes-and-towelettes-disinfecting",
    url: "/ec/wipes-towelettes-disinfecting-infection-control-clinical-l-523-719",
    image: "/media/shared/common/mp/metrex/caviwipes/small/72110-CaviWipes.png",
  },
  {
    name: "Masks",
    id: "masks",
    url: "/ec/masks-infection-control-personal-l-509-567",
    image: "/media/shared/common/mp/mydent/breathe-e-z/small/mydent-breath-e-z-mk-1246.jpg",
  },
  {
    name: "Impression materials",
    id: "impression-materials",
    url: "/ec/impression-materials-t-524",
    image: "/media/shared/common/mp/house-brand/house-brand/small/house-bite-registration.png",
  },
  {
    name: "Cosmetic dentistry products",
    id: "cosmetic-dentistry-products",
    url: "/ec/cosmetic-t-531",
    image:
      "/media/shared/common/mp/3m-espe/filtek-z250/small/3m-espe-filtek-z250-universal-composite-restorative-compules.jpg",
  },
  {
    name: "Anesthetic products",
    id: "anesthetic-products",
    url: "/ec/anesthetic-t-536",
    image:
      "/media/shared/common/mp/septodont/septocaine/small/septodont-septocaine-articaine-hcl-4-local-anesthetic-epinephrine-1-100000.png",
  },
  {
    name: "Preventives",
    id: "preventives",
    url: "/ec/preventives-t-515",
    image: "/media/shared/common/mp/dentsply-professional/nupro/small/nupro-prophy-paste-fluoride.png",
  },
  {
    name: "Evacuation products",
    id: "evacuation-products",
    url: "/ec/evacuation-t-527",
    image: "/media/shared/common/mp/house-brand/house-brand/small/saliva-ejectors-ZCBI.png",
  },
  {
    name: "Disposables",
    id: "disposables",
    url: "/ec/disposables-t-529",
    image: "/media/shared/common/mp/house-brand/house-brand/small/dry-back-dental-bibs.png",
  },
  {
    name: "Endodontic products",
    id: "endodontic-products",
    url: "/ec/endodontic-t-528",
    image: "/media/shared/common/mp/coltene-whaledent/endo-ice/small/refrigerant-spray-H05032.png",
  },
  {
    name: "Cements, liners & adhesives",
    id: "cements-liners-and-adhesives",
    url: "/ec/cements-liners-adhesives-t-533",
    image: "/media/shared/common/mp/gc-america/gc-fujicem-2/small/gc-fujicem-2-cement-441002.png",
  },
  {
    name: "Instruments",
    id: "instruments",
    url: "/ec/instruments-t-510",
    image: "/media/shared/common/mp/house-brand/house-brand/small/hf-front-metal-mirror-MIR4-12.png",
  },
];

export type HighlightedCategory = {
  name: string;
  id: string;
  url: string;
  image: string;
};

export default highlightedCategories;
