"use client";

import StandardPage from "@/app/layouts/standard/standardPage";
import { useEffect } from "react";

import UnknownError from "@/app/errors/unknownError";

export default function ErrorPage({ error }: { error: Error; reset: () => void }) {
  useEffect(() => {
    console.error(error);
  }, [error]);
  return (
    <StandardPage>
      <UnknownError />
    </StandardPage>
  );
}
