"use client";

import styles from "@/app/errors/unknown.module.scss";

interface FallbackProps {
  error?: Error;
}

const UnknownError: React.FC<FallbackProps> = ({ error }) => {
  return (
    <div id={styles["server-offline-or-in-error-state"]}>
      <div>
        <h1>We&apos;ll be right back</h1>
        <span>
          Net32 is busy updating <br />
          all dental products for you
          <br />
          and will be back soon.
          <br />
          Thank you for your patience.
        </span>
      </div>
      {process.env.NEXT_PUBLIC_DEV_MODE === "true" && (
        <pre>
          {error && error.message}
          {error && error.stack}
        </pre>
      )}
    </div>
  );
};

export default UnknownError;
